<template>
<div class="meback">
    <div class="list">
        <div class="list-cell">
            <div class="wtitle w2">账号类型</div>
            <div class="wtitle w3">账号名</div>
            <div class="wtitle w3">卡号</div>
            <div class="wtitle w2">操作</div>
        </div>
        <div class="list-sp"></div>
        <div v-for="item in data.accountList" :key="item.account_code" class="list-cell">
            <div class="wdesc w2">{{data.typemap[item.account_type]}}</div>
            <div class="wdesc w3">{{item.account_name}}</div>
            <div class="wdesc w3">{{item.bank_card}}</div>
            <div class="wdesc2 w2">
                <div @click="editAccount(item)" class="wbtn">编辑</div>
                <div @click="delAccount(item)" class="wbtn ml">删除</div>
            </div>
        </div>
    </div>

    <img v-if="data.accountList.length==0" src="@/assets/img/common/noaccount.png" class="nodata"/>
    <div v-if="data.accountList.length==0" style="margin:1rem;">
        <van-button @click="clickAdd()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">添加提现账号</van-button>

    </div>
    
    <div class="service">
		<div class="service-title2">客云云科技 · 让经纪人生活更美好</div>
		<div class="service-title3">移动办公 合规平台 专业服务</div>
	</div>
    
    <!----------菜单开始------------->
    <Menu :emodel="menuIndex"></Menu>
    <!----------菜单结束------------->

    <div style="height:100px;"></div>
</div>

<div v-if="data.accountShow||data.phoneShow||data.typeShow" class="bback">
</div>

<!-----------绑定账号开始------------>
<div v-if="data.accountShow" class="pop-city">
	<div class="pop-close" @click="data.accountShow=false">×</div>
	<div class="pop-con">

		<div class="pop-title">新增{{data.model.account_type=="01"?"私人":"对公"}}收款账户</div>

		<div v-if="data.model.account_type=='02'" class="field-div mt">
			<img src="../../assets/img/customer/icon_level.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="data.model.account_name"
					   clearable
					   placeholder="公司名称" />

		</div>


		<div class="pop-rel">
			<div class="field-div mt">
				<img src="../../assets/img/customer/icon_floor.png" />
				<div class="field-sp"></div>
				<van-field
						   style="border-radius:2rem"
						   v-model="data.model.bank"
						   clearable
						   placeholder="所属银行" />

			</div>
		</div>
        <div class="pop-rel">
			<div class="field-div mt">
				<img src="../../assets/img/customer/icon_route.png" />
				<div class="field-sp"></div>
				<van-field
						   style="border-radius:2rem"
						   v-model="data.model.bank_card"
						   clearable
						   placeholder="卡号" />

			</div>
		</div>
        <div class="field-div mt" v-if="data.model.account_type=='02'">
			<img src="../../assets/img/customer/icon_loc.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="data.model.address"
					   clearable
					   placeholder="公司地址" />

		</div>
		<div v-if="data.model.account_type=='01'" class="field-div mt">
			<img src="../../assets/img/index/icon_user3.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="data.model.account_name"
					   clearable
					   placeholder="户主姓名" />

		</div>
		<div class="field-div mt">
			<img src="../../assets/img/customer/icon_card2.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="data.model.cert"
					   clearable
					   :placeholder="data.model.account_type=='02'?'纳税人识别码':'身份证号'" />

		</div>

		<div class="field-div mt">
			<img src="../../assets/img/index/icon_phone3.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="data.model.phone"
					   clearable
					   placeholder="手机号" />
		</div>

        <div class="fa-div-radio" style="margin-top:0.5rem;">
            <div class="fa-chk">
            <img @click="changeChk('1')" v-if="model.chk!='1'" src="@/assets/img/index/icon_check.png"/>
            <img @click="changeChk('0')" v-if="model.chk=='1'" src="@/assets/img/index/icon_check_sel.png"/>
            </div>
            <div class="fa-label-xy">我已阅读并同意<a @click="clickXy(1)">《用户协议》</a>、<a  @click="clickXy(2)">《隐私协议》</a>及<a @click="clickXy(3)">《内容平台协议》</a></div>
        </div>
	</div>
	<div class="pop-btn" style="display:flex;">
		<van-button @click="accountSave()" style="background-color:#C90000;" size="large" round type="danger">绑定账号</van-button>

	</div>
</div>
<!-----------绑定账号结束------------>
<!-----------手机号验证码开始------------>
<div v-if="data.phoneShow" class="pop-city">
	<div class="pop-close" @click="data.phoneShow=false">×</div>
	<div class="pop-con">

		<div class="pop-title">身份验证</div>
        <div class="field-div mt">
			<img src="../../assets/img/index/icon_phone3.png" />
			<div class="field-sp"></div>
			<van-field
					   style="border-radius:2rem"
					   v-model="model.phone"
					   clearable
					   placeholder="请输入手机号" />
		</div>

       

      <div class="field-div-yzm mt">
        <div class="field-div">
          <img style="width:1.3rem;" src="@/assets/img/index/icon_shield3.png"/>
          <van-field
           style="border-radius:2rem;"
            v-model="model.sms"
            clearable
            placeholder="请输入验证码"
          />
        </div>
        <van-button @click="clickSms()" style="width:10rem;margin-left:1rem;background-color:#3a8af2" round type="primary">{{data.smstxt}}</van-button>
      </div>

    </div>

	<div class="pop-btn" style="display:flex;">
		<van-button @click="checkSms()" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>
	</div>
</div>
<!-----------手机号验证码结束------------>
<div v-if="data.typeShow" class="pop-city">
	<div class="pop-close" @click="data.typeShow=false">×</div>
	<div class="pop-con">
        <div class="pop-title">请选择收款账户</div>
    </div>

	<div class="pop-btn" style="display:flex;margin-top:4rem;margin-bottom:4rem;">
            <van-button @click="clickType('01')" class="mt fa-btn" style="border:1px solid #3d6eff;background-color: #fff;color:#3d6eff;" size="large" round type="danger">私人账号</van-button>
			<van-button @click="clickType('02')" class="mt fa-btn" style="margin-left:1rem;border:1px solid #3d6eff;background-color: #fff;color:#3d6eff" size="large" round type="danger">对公账号</van-button>

	</div>
</div>
<van-dialog />
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,nullStr,showMessage} from '../../common/utils.js'
import { areaList } from '@vant/area-data';
import { Dialog } from 'vant';


let route=useRoute()
let router=useRouter()
let menuIndex={index:4}

let data=reactive({
  accountShow:false,
  phoneShow:false,
  typeShow:false,
  accountList:[],
  model:{},
  accountType:"",
  typemap:{"01":"个人账户","02":"对公账户"},
  smstxt:"获取验证码",
    time:0,
    timer:'',
    model:{}
})

function clickType(type){
    data.accountType=data.typemap[type];
    data.model.account_type=type;
    data.typeShow=false;
    data.phoneShow=true;
}

function editAccount(item){
    data.model=item;
    data.phoneShow=true;
}

function delAccount(item){
    data.model=item;
    Dialog.confirm({
    title: '删除确认',
    message:
        '确定要删除账号吗？',
    })
    .then(() => {
        api.post("/web/account/accountdel",{accountCode:data.model.account_code}).then((res)=>{
            if(res.errcode=="0"){
                data.model={};
            showMessage("删除成功");
            searchAccount();
            }else{
            showMessage(res.errmsg);
            }
        })
    })
    .catch(() => {
        // on cancel
    });
}

let model=reactive({
    phone:"",
    sms:"",
    chk:'0'
})


//获取短信验证码
function sms(){
    data.time--;
    data.smstxt='重新获取('+data.time+'s)';
    if(data.time == 0){
        clearInterval(data.timer);
        data.smstxt='重新获取';
    }
}

function clickSms(){
  if(isNull(model.phone)){
    showMessage("请输入手机号");
    return;
  }
  if(data.time>0){
    return;
  }
  api.post("/web/sms/sms",model).then((res)=>{
        if(res.errcode=="0"){
          data.time=60;
          data.timer = setInterval(sms, 1000);
        }else{
          showMessage(res.errmsg);
        }
  })
}

function checkSms(){
    api.post("/web/sms/check",model).then((res)=>{
        if(res.errcode=="0"){
          data.phoneShow=false;
          data.accountShow=true;
        }else{
          showMessage(res.errmsg);
        }
  })
}

function changeChk(flag){
  model.chk=flag;
}

function clickXy(code){
  router.push({ path: '/xy',query:{"newsCode":code} })
}

function clickAdd(){
    data.typeShow=true;
}


function accountSave(){
    if(isNull(data.model.account_type)){
        showMessage("请输入选择账户类型");
        return;
    }
    if(data.model.account_type=="01"){
        if(isNull(data.model.bank)){
            showMessage("请输入所属银行");
            return;
        }
        if(isNull(data.model.bank_card)){
            showMessage("请输入银行卡号");
            return;
        }
        if(isNull(data.model.account_name)){
            showMessage("请输入户主姓名");
            return;
        }
        if(isNull(data.model.cert)){
            showMessage("请输入身份证号");
            return;
        }
        if(isNull(data.model.phone)){
            showMessage("请输入手机号");
            return;
        }
    }else if(data.model.account_type=="02"){
        if(isNull(data.model.account_name)){
            showMessage("请输入公司名称");
            return;
        }
        if(isNull(data.model.bank)){
            showMessage("请输入所属银行");
            return;
        }
        if(isNull(data.model.bank_card)){
            showMessage("请输入银行卡号");
            return;
        }
        if(isNull(data.model.address)){
            showMessage("请输入公司地址");
            return;
        }
        if(isNull(data.model.cert)){
            showMessage("请输入纳税人识别码");
            return;
        }
        if(isNull(data.model.phone)){
            showMessage("请输入手机号");
            return;
        }
    }
    if(model.chk!="1"){
        showMessage("请阅读并同意协议");
        return;
    }
    data.model.smsPhone=model.phone;
    data.model.sms=model.sms;
    api.post("/web/account/accountsave",data.model).then((res)=>{
        if(res.errcode=="0"){
          showMessage("保存成功");
          data.accountShow=false;
          searchAccount();
        }else{
          showMessage(res.errmsg);
        }
    })
}

function searchAccount(){
  api.post("/web/account/accountlist",{}).then((res)=>{
        if(res.errcode=="0"){
          data.accountList=res.rows;
        }else{
          showMessage(res.errmsg);
        }
  })
  
}
searchAccount();

</script>
<style scoped lang="scss">
.pop-city{
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .pop-con{
    margin-left: 1rem;
    margin-right: 1rem;
    .pop-title{
        text-align: center;
        margin-bottom:1rem;
    }

    .mt{
      margin-top:0.5rem;
    }
    .pop-rel{
      position: relative;
      .pop-sel{
        position: absolute;
        border: 1px solid #949999;
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fff;
        z-index: 999;
        right: 0px;
        left: 0px;
        .pop-sel-div{
          display: flex;
          height:2.5rem;
          justify-content: center;
          &.bot{
            border-bottom: 1px solid #949999;
          }
          .pop-sel-div-rad{
            width:1rem;
            height:1rem;
            margin-top: 0.75rem;
          }
          .pop-sel-div-lab{
            line-height:2.5rem;
            margin-left: 1rem;;
          }
        }
      }
    }
    .field-div{
      border: 1px solid #949999;
      border-radius: 2rem;
      padding-left: 1rem;
      display: flex;
      align-items:center;
      .field-sp{
        width:1px;
        background: #ececec;
        height: 1rem;
        margin-left:0.8rem;
      }
      .field-div-label{
        margin:0.8rem;
        color:#afb4bd;
      }
      .field-unit-sub{
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
        display: flex;
        .field-sp-sub{
          width:1px;
          background: #ececec;
          height: 1rem;
          margin-top:0.8rem;
        }
        .dt2-sj-b{
            margin-top: 1.3rem;
            width: 100%;
            text-align: center;
            justify-content: center;
            justify-items: center;
            display: flex;
        }
        .dt2-sj{
            width: 0px;
            height: 0px;
            border: 0.4rem solid transparent;
            border-top-color: #949999;
        }
      }
      .field-unit{
        background: #929898;
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
      }
      img{
        width: 1rem;
        height: 1.3rem;
        vertical-align: middle;
      }
    }

    .field-div-yzm{
    display: flex;
  }
  .fa-chk{
    img{
      width:1rem;
    }
  }

  .fa-div-radio{
    margin-top:1rem;
    padding-left:1rem;
    display: flex;
    align-items: center;
    .fa-radio{
      width:0.8rem;
      height:0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel{
        width:0.6rem;
        height:0.6rem;
        margin:0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label{
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color:#0A1E3C;
    }
    .fa-label-xy{
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color:#afb4bd;
      a{
        color:#3D6FFF;
      }
    }

     
  }
  }
  
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-wtitle{
    margin-left: 3rem;
    margin-right: 3rem;
    .pop-title{
      font-size: 1rem;
      font-weight: bold;
    }
    .pop-sp{
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      height: 1px;
      background: #ebebeb;
    }
    .pop-title-sub{
      color:#949999;
      margin-bottom:0rem;
      font-size: 0.9rem;
    }
    
    .pop-title-label{
      margin-bottom:1rem;
      .pop-title-label-city{
        border: 1px solid #ebebeb;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        float: left;
        margin-top: 0.5rem;
      }
      .clear{
        clear: both;
      }
    }
  }
  .pop-sph{
    height: 0.3rem;
    background: #ebebeb;
  }
  .pop-picker{
    
  }
  .pop-btn{
    margin:1rem;
  }
}
.match-product{
    margin-left:0px;
    margin-right:0px;
    padding: 1rem;
    background: #eff1f2;
    .match-product-item{
        padding:1rem;
        background: #fff;
        margin-bottom:1rem;
        border-radius: 0.5rem;
        

        .item-news-right{
            position: absolute;
            right:1rem;
            margin-top:0.1rem;
            margin-top: 0.3rem;
            width: 3.4rem;
            font-size: 0.8rem;
            .item-news-right-title{
                background: #feae18;
                margin-left: -1.3rem;
                margin-top: -1.3rem;
                color: #fff;
                text-align: center;
                border-bottom-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }
            
        }

        .item-news{
            border: 30px solid #c90100;
            height: 0;
            width: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
            position: absolute;
            margin-left: -1rem;
            margin-top: -1rem;
            border-top-left-radius: 1rem;
            .item-news-title{
                margin-left: -1.3rem;
                margin-top: -1.3rem;
                color: #fff;
            }
            
        }
        .item-bottom{
            margin-top: 0.3rem;
            display: flex;
            color:#a5a9a9;
            font-size:0.8rem;
            .item-bottom-label{
                display: flex;
                .desc{
                    font-size:0.8rem;
                    color:#3e4043;
                }
            }
            .item-bottom-time{
                margin-left: auto;
            }
        }

        .item-center{
            display: flex;
            border-bottom: 1px solid #f3f3f3;
            .item-center-btn{
                background: #3b6eff;
                color:#fff;
                padding: 1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                font-size: 0.8rem;
                border-radius: 1rem;
                margin-left:auto;
            }
        }

        .item-hr{
            height: 1px;
            background: #f3f3f3;
            margin-top:0.3rem;
            margin-bottom:0.3rem;
        }
        .item-label{
            margin-top:1rem;
            margin-left:2rem;
            margin-right:2rem;
            font-size:0.9rem;
            .item-label-div{
                margin-bottom:0.5rem;
                display:flex;
                img{
                    width:1.2rem;
                    height:1.2rem;
                }
                div{
                    margin-left:0.4rem;
                }
            }
        }
        .item-top{
            display:flex;
            .item-top-img{
                width:4rem;
                height:4rem;
            }
            .item-top-desc{
                margin-left:1rem;
                flex:1;
                .item-back{
                    margin-top:0.5rem;
                    font-size:0.9rem;
                    width:100%;
                    .item-back-desc{
                        display: inline-block;
                        color:#3D6FFF;
                        border:1px solid #3D6FFF;
                        padding-left:0.3rem;
                        padding-right:0.3rem;
                        margin-right:0.5rem;
                        border-radius:0.3rem;
                        margin-bottom: 0.3rem;
                    }

                    .item-back-desc1{
                        display: inline-block;
                        background:#3D6FFF;
                        color:#fff;
                        padding-left:0.3rem;
                        padding-right:0.3rem;
                        margin-right:0.5rem;
                        border-radius:0.3rem;
                        margin-bottom: 0.3rem;
                    }

                    .item-back-desc2{
                        display: inline-block;
                        background:#c80303;
                        color:#fff;
                        padding-left:0.3rem;
                        padding-right:0.3rem;
                        margin-right:0.5rem;
                        border-radius:0.3rem;
                        margin-bottom: 0.3rem;
                    }
                }
                .item-prop{
                    color:#949999;
                    font-size:0.9rem;
                    width:100%;
                    .item-prop-desc{
                        display: inline-block;
                        margin-right:1rem;
                        margin-top:0.5rem;
                        .red{
                            margin-left:0.2rem;
                            color:#C90000;
                        }
                        .black{
                            margin-left:0.2rem;
                            color:#0A1E3C;
                        }
                    }
                }
                .item-name{
                    display:flex;
                    .item-name-name{
                        font-weight:500;
                    }
                    .item-name-desc{
                        margin-left:auto;
                        font-size:0.8rem;
                        margin-left:auto;
                        color:#3D6FFF;
                    }
                }
                .item-warn{
                    background: #fcefe5;
                    color: #d21616;
                    padding-left: 0.3rem;
                    padding-right: 0.3rem;
                    border-radius: 0.2rem;
                    display: inline;
                }
            }
        }
    }
}
.nodata{
    width:100%;
    margin-top:2rem;
    margin-bottom:2rem;
}
.list{
    margin-top:2rem;
    .list-sp{
        margin:1rem;
        height:1px;
        background:#ebebeb;
    }
    .list-cell{
        display:flex;
        font-size:0.8rem;
        .wtitle{
            text-align: center;
            font-size:1rem;
        }
        .wdesc{
            text-align: center;
        }
        .wdesc2{
            display:flex;
            justify-content: center;
            .wbtn{
                border: 1px solid #618afe;
                border-radius:0.3rem;
                color:#618afe;
                padding-left:0.2rem;
                padding-right:0.2rem;
                &.ml{
                    margin-left:0.5rem;
                }
            }

        }
        .w2{
            flex:2;
        }
        .w3{
            flex:3;
        }
    }
}

.service{
  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.meback{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url('../../assets/img/me/me.png');
    background-size: 100% 100%;
}
</style>